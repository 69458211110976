import React, { createContext, Dispatch, useReducer } from 'react'
import { DateTime } from 'luxon'
import { ReservationType } from '../../models/ReservationType'
import { Employee } from '../../models/Employee'

export interface CustomerForm {
  firstName?: string
  lastName?: string
  phoneNumber?: string
  email?: string
}

export interface ReservationFormData {
  id?: number
  reservationType?: Pick<ReservationType, 'id' | 'name'>
  employee?: Pick<Employee, 'id' | 'firstName' | 'lastName'>
  date?: DateTime
  customer?: CustomerForm
}

interface ReservationFormState {
  step: number
  data: ReservationFormData
  branchId?: string
}

export interface SetReservationFormStepAction {
  type: typeof SET_STEP
  value: number
}

export interface SetReservationFormDataAction {
  type: typeof SET_DATA
  value: ReservationFormData
}

export interface SetReservationFormBranchAction {
  type: typeof SET_BRANCH
  value: string | undefined
}

export type ReservationFormActionType =
  | SetReservationFormStepAction
  | SetReservationFormDataAction
  | SetReservationFormBranchAction

export const SET_STEP = 'reservation-form/set-step'
export const SET_DATA = 'reservation-form/set-data'
export const SET_BRANCH = 'reservation-form/set-branch'

const FormReducer = (
  state: ReservationFormState,
  action: ReservationFormActionType
): ReservationFormState => {
  switch (action.type) {
    case SET_STEP:
      return {
        ...state,
        step: action.value,
      }
    case SET_DATA:
      return {
        ...state,
        data: action.value,
      }
    case SET_BRANCH:
      return {
        ...state,
        branchId: action.value,
      }
    default:
      return state
  }
}

export const setReservationFormStepAction = (step: number): SetReservationFormStepAction => ({
  type: SET_STEP,
  value: step,
})

export const setReservationFormDataAction = (
  data: ReservationFormData
): SetReservationFormDataAction => ({
  type: SET_DATA,
  value: data,
})

const initialFormState: ReservationFormState = {
  step: 1,
  data: {},
  branchId: undefined,
}

const ReservationFormStateContext = createContext<{
  formState: ReservationFormState
  dispatch: Dispatch<ReservationFormActionType>
}>({
  formState: initialFormState,
  dispatch: () => null,
})

const ReservationFormStateProvider: React.FC = ({ children }) => {
  const [formState, dispatch] = useReducer(FormReducer, initialFormState)
  return (
    <ReservationFormStateContext.Provider value={{ formState, dispatch }}>
      {children}
    </ReservationFormStateContext.Provider>
  )
}

ReservationFormStateContext.displayName = 'ReservationFormStateContext'

export { ReservationFormStateProvider, ReservationFormStateContext }
